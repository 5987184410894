var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "header",
    [
      _c("navigation-top", { on: { resetExtraMenu: _vm.resetExtraMenu } }),
      _c("toolbar", {
        attrs: { "reset-extra-menu-title": _vm.resetExtraMenuTitle },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }