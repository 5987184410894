<template>
  <header>
    <navigation-top @resetExtraMenu="resetExtraMenu" />
    <toolbar :reset-extra-menu-title="resetExtraMenuTitle" />
  </header>
</template>

<script>
import NavigationTop from '@/components/layouts/default/header/Navigation'
import Toolbar from '@/components/layouts/default/header/toolbar/Toolbar'
export default {
  name: 'Header',
  components: { NavigationTop, Toolbar },
  data() {
    return { resetExtraMenuTitle: 1 }
  },
  methods: {
    resetExtraMenu() {
      ++this.resetExtraMenuTitle
    },
  },
}
</script>

<style lang="sass">
header .header
  &--wrapper
    display: flex
    justify-content: center
    background: $color-blue-primary

    &_toolbar
      background: #fff
      line-height: 69px
      box-shadow: 0 4px 44px 4px rgba(0, 0, 0, 0.13)
      @include to(1024px)
        line-height: 50px

    & .navigation
      width: 100%
      display: flex
      flex-direction: row
      align-content: space-around
      justify-content: space-between
</style>
